@media (min-width: 1600px) {
    .bodyHome{
        min-height: 65vh !important;
    }
}
@media (max-width: 1700px) {
    .bodyHome{
        min-height: 74vh !important;
    }
}


